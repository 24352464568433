import axios from 'axios';
import store from '../store';

export default {
    get: () => axios.get('customers'),
    store: (data) => axios.post('customers/store', data),
    parseCIF: (data) => axios.post('customers/cif', data),
    update: (data) => axios.put('customers/'+data.id, data),
    delete: (id) => axios.delete('customers/'+id, ),
    getTemplate: () => axios.get('customers/download-template').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    import: (data) => axios.post('customers/import', data, {headers: {"Content-Type": "multipart/form-data",}}),
}