<template>
  <div>
    <CRow>
      <CCol sm="12">
        <MyPaymentsList
          :items="customers"
          hover
          striped
          border
          small
          fixed
          caption="Mis Pagos"
          @refresh="refreshItems" 
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import MyPaymentsList from '../../components/configuration/MyPaymentsList.vue'
import customers from '../../services/customers';
import store from '../../store'

export default {
  name: 'MyPayments',
  components: { MyPaymentsList },
  data: function () {
		return {
            customers: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await customers.get(); 

    if(response.type == "success"){
      this.customers = response.data;
    }
    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.customers = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
